import {loadClientConfig} from "up-form";
const lodash = require("lodash");
export const {name, version} = require("../package.json");
export const stage = process.env.REACT_APP_STAGE || "unknown";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const clientConfigKey = `config:${name}:${version}`;

const upApiOverride = process.env.REACT_APP_UP_API_URI;

const data = {
    default: {},

    unknown: {
        upApi: {
            baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/"
        }
    },

    local: {
        upApi: {
          baseUri: upApiOverride || "http://localhost:3030/"
        }
    },
    development: {
        upApi: {
            baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/"
        }
    },
    test: {
        upApi: {
          baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
        }
    },
    uat: {
        upApi: {
          baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
        }
    }, 
    production: {
        upApi: {
          baseUri: "https://up-int-apim-prod.azure-api.net/"
        }
    }
};

export const baseConfig = lodash.merge({}, data["default"], data[stage]);
const config = isDev ? lodash.merge({}, baseConfig, loadClientConfig(clientConfigKey)) : baseConfig;
export default config;