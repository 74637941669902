import React from 'react';
import { MenuItem, Box, TextField } from '@material-ui/core';

function ChooseCourse({formData, setFormData, courses, errorState, setErrorState}) {

    const handleCourseChange = (event) => {
        setFormData({...formData, intakeId: event.target.value, intakeName: event.currentTarget.innerText});
    }

    const doValidation = (e) => {
        const data = {...errorState};

        if (e.target.value === "") {
            data[e.target.name] = true;
            setErrorState(data)
        } else {
            data[e.target.name] = false;
            setErrorState(data)
        }
    }
   
    return (
    <Box sx={{paddingY:5}}>
        <TextField
            fullWidth
            select
            label="Select a Course"
            variant='outlined'
            required
            error={errorState.intakeId}
            name="intakeId"
            value={formData.intakeId}
            helperText={errorState.intakeId && "Course is required"}
            inputProps={{onBlur: (event) => doValidation(event)}}
                onChange={event => {
                    handleCourseChange(event);
                    doValidation(event);
                }}
        >
            {courses.filter((course) => {return course.courseClassificationCode === "100000000" || course.courseClassificationCode === "809730003"}) ///100000000 is holiday course and 809730003 is school course types
                    .map((course) => course.intakeList).map((intakes) => intakes.map((intake) => <MenuItem key={intake.name} value={intake.intakeCRMId}>{intake.name}</MenuItem>))}
        </TextField>
    </Box>
    );
}

export default ChooseCourse;