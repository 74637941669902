import React, { useState } from "react";
import UpApi from "up-api";
import { Grid, Paper, TextField, Typography, Box, Button, MenuItem, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';

function StudentDetails({ formData, setFormData, setPage, errorState, setErrorState }){
    let data = {...formData};
    const [open, setOpen] = useState(false);
    const [apiResError, setApiResError] = useState(false);

    //adds student data to state
    const handleStudentSectionChange = (index, event) => {
        let data = {...formData};
        data.students[index][event.target.name] = event.target.value;
        setFormData(data);
    }
    
    //adds emergency contact data to state
    const handleEmergencySectionChange = (index, event) => {
        let data = {...formData};
        data.students[index].emergencyContact[event.target.name] = event.target.value;
        setFormData(data);
    }

    const doStudentValidation = (index, e) => {
        let data = {...errorState};
        if (e.target.name === "emailAddress" && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value))) {
            data.studentDetails[index][e.target.name] = true;
            setErrorState(data);
        }
        else if (e.target.name === "nsn" && !(e.target.value.length <= 10 && !(/\D/.test(e.target.value)))) {
            data.studentDetails[index][e.target.name] = true;
            setErrorState(data);
        }
        else if (e.target.value === "") {
            data.studentDetails[index][e.target.name] = true;
            setErrorState(data)
        } else {
            data.studentDetails[index][e.target.name] = false;
            setErrorState(data)
        }
    }

    const doEmergencyValidation = (index, e) => {
        let data = {...errorState};
        if (e.target.name === "emailAddress" && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value))) {
            data.studentDetails[index].emergencyContact[e.target.name] = true
            setErrorState(data);
        }
        else if (e.target.name !== "emailAddress" && e.target.value === "") {
            data.studentDetails[index].emergencyContact[e.target.name] = true;
            setErrorState(data)
        } else {
            data.studentDetails[index].emergencyContact[e.target.name] = false;
            setErrorState(data)
        }
    }

    //dynamically adds new student fields to form and state
    const addFields = () => {
        let newFields = {
            firstName: "",
            middleName: "",
            lastName: "",
            preferredName: "",
            nsn: "",
            emailAddress: "",
            mobilePhone: "",
            dateOfBirth: "",
            gender: "",
            disability: "",
            disabilityNote: "",
            emergencyContact: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                mobilePhone: ""
            }
        };

        let newErrorFields = {
            firstName: false,
            lastName: false,
            emailAddress: false,
            nsn: false,
            dateOfBirth: false,
            disability: false,
            emergencyContact: {
                firstName: false,
                lastName: false,
                emailAddress: false
            }
        }

        setFormData({...formData, students: [...formData.students, newFields]});
        setErrorState({...errorState, studentDetails: [...errorState.studentDetails, newErrorFields]});
    }

    //removes student fields from the form and state based on its index 
    const removeFields = (index, event) => {
        let data = {...formData};
        let errorData = {...errorState};
        data.students.splice(index, 1);
        errorData.studentDetails.splice(index, 1);
        setFormData(data);
        setErrorState(errorData);
        event.preventDefault();
    }

    //calls createBulkStudentApplication Logic app
    const sendPayload = async () => {
        try {
            await UpApi.bulkStudentApplication(formData);
            setPage((currPage) => currPage + 1);
        } catch (e) {
            setOpen(false);
            setApiResError(true);
        }
    }

    //Checks to see if required student and emergency fields are empty
    const disableButton = formData.students.some((field, index) => {
        const {emergencyContact, disabilityNote, middleName, mobilePhone, preferredName, gender, ...requiredStudentFields} = field;
        const {mobilePhone: phone, ...requiredEmergencyFields} = emergencyContact;
        const joinArr = Object.values(requiredStudentFields).concat(Object.values(requiredEmergencyFields));
        return !(!joinArr.includes("") && !errorState.studentDetails[index].emailAddress && !errorState.studentDetails[index].nsn && !errorState.studentDetails[index].emergencyContact.emailAddress);
    });

    return (
        <>
            <form>
                {data.students.map((input, index) => {
                return (
                    <div key={index}>
                        <Box sx={{paddingY:2}}>
                            <Paper variant="outlined">
                                <Box sx={{paddingY:2, marginX:5}}>
                                <Typography style={{marginBottom: "1em"}} variant="h6">Student Details</Typography>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='firstName'
                                                label='First Name'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.firstName}
                                                error={errorState.studentDetails[index].firstName}
                                                helperText={errorState.studentDetails[index].firstName && "First Name is required"}
                                                InputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                                onChange={event => {
                                                    handleStudentSectionChange(index, event);
                                                    doStudentValidation(index, event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='middleName'
                                                label='Middle Name'
                                                variant="outlined"
                                                fullWidth
                                                value={input.middleName}
                                                onChange={event => handleStudentSectionChange(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='lastName'
                                                label='Last Name'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.lastName}
                                                error={errorState.studentDetails[index].lastName}
                                                helperText={errorState.studentDetails[index].lastName && "Last Name is required"}
                                                InputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                                onChange={event => {
                                                    handleStudentSectionChange(index, event);
                                                    doStudentValidation(index, event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='preferredName'
                                                label='Preferred Name'
                                                variant="outlined"
                                                fullWidth
                                                value={input.preferredName}
                                                onChange={event => handleStudentSectionChange(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='nsn'
                                                label='NSN Number'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.nsn}
                                                error={errorState.studentDetails[index].nsn}
                                                helperText={errorState.studentDetails[index].nsn && "NSN is required and must be 10 digits or less"}
                                                InputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                                onChange={event => {
                                                    handleStudentSectionChange(index, event);
                                                    doStudentValidation(index, event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='emailAddress'
                                                label='Email'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.emailAddress}
                                                error={errorState.studentDetails[index].emailAddress}
                                                helperText={errorState.studentDetails[index].emailAddress && "Please enter a valid Email Address"}
                                                InputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                                onChange={event => {
                                                    handleStudentSectionChange(index, event)
                                                    doStudentValidation(index, event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='mobilePhone'
                                                label='Mobile Number'
                                                type="tel"
                                                variant="outlined"
                                                fullWidth
                                                value={input.mobilePhone}
                                                onChange={event => handleStudentSectionChange(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name= "dateOfBirth"
                                                type="Date"
                                                variant="outlined"
                                                label="Date of Birth"
                                                InputLabelProps={{shrink: true}}
                                                required
                                                fullWidth
                                                value={input.dateOfBirth}
                                                error={errorState.studentDetails[index].dateOfBirth}
                                                helperText={errorState.studentDetails[index].dateOfBirth && "Date of Birth is required"}
                                                InputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                                onChange={event => {
                                                    handleStudentSectionChange(index, event)
                                                    doStudentValidation(index, event);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            variant="outlined"
                                            label="Gender"
                                            name="gender"
                                            value={input.gender}
                                            onChange={event => {
                                                handleStudentSectionChange(index, event);
                                            }}
                                            >
                                            <MenuItem key="Male" value="Male">Male</MenuItem>
                                            <MenuItem key="Female" value="Female">Female</MenuItem>
                                            <MenuItem key="Diverse" value="Diverse">Diverse</MenuItem>
                                        </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            required
                                            variant="outlined"
                                            label="Disabilities"
                                            name="disability"
                                            value={input.disability}
                                            error={errorState.studentDetails[index].disability}
                                            helperText={errorState.studentDetails[index].disability && "Disability value is required"}
                                            inputProps={{onBlur: (event) => doStudentValidation(index, event)}}
                                            onChange={event => {
                                                handleStudentSectionChange(index, event);
                                                doStudentValidation(index, event);
                                            }}
                                            >
                                            <MenuItem key="Yes" value="403d6a69-a8d8-e711-812b-c4346bdee581">Yes</MenuItem>
                                            <MenuItem key="No" value="343d6a69-a8d8-e711-812b-c4346bdee581">No</MenuItem>
                                            <MenuItem key="Not Stated" value="443d6a69-a8d8-e711-812b-c4346bdee581">Not Stated</MenuItem>
                                        </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name='disabilityNote'
                                                label='Disability Notes'
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                maxRows={4}
                                                value={input.disabilityNote}
                                                onChange={event => handleStudentSectionChange(index, event)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography style={{marginBottom: "1em", paddingTop: '30px'}} variant="h6">Emergency Contact Details</Typography>
                                    <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='firstName'
                                                label='First Name'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.emergencyContact.firstName}
                                                error={errorState.studentDetails[index].emergencyContact.firstName}
                                                helperText={errorState.studentDetails[index].emergencyContact.firstName && "First Name is required"}
                                                InputProps={{onBlur: (event) => doEmergencyValidation(index, event)}}
                                                onChange={event => {
                                                    handleEmergencySectionChange(index, event);
                                                    doEmergencyValidation(index, event);
                                                }}
                                            />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='lastName'
                                                label='Last Name'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.emergencyContact.lastName}
                                                error={errorState.studentDetails[index].emergencyContact.lastName}
                                                helperText={errorState.studentDetails[index].emergencyContact.lastName && "Last Name is required"}
                                                InputProps={{onBlur: (event) => doEmergencyValidation(index, event)}}
                                                onChange={event => {
                                                    handleEmergencySectionChange(index, event);
                                                    doEmergencyValidation(index, event);
                                                }}
                                            />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='emailAddress'
                                                label='Email'
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={input.emergencyContact.emailAddress}
                                                error={errorState.studentDetails[index].emergencyContact.emailAddress}
                                                helperText={errorState.studentDetails[index].emergencyContact.emailAddress && "Please enter a valid Email Address"}
                                                InputProps={{onBlur: (event) => doEmergencyValidation(index, event)}}
                                                onChange={event => {
                                                    handleEmergencySectionChange(index, event);
                                                    doEmergencyValidation(index, event);
                                                }}
                                            />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <TextField
                                                name='mobilePhone'
                                                label='Mobile Number'
                                                type="tel"
                                                variant="outlined"
                                                fullWidth
                                                value={input.emergencyContact.mobilePhone}
                                                onChange={event => handleEmergencySectionChange(index, event)}
                                            />
                                            </Grid>
                                    </Grid>
                                <Box sx={{display:"flex", justifyContent: "flex-end", paddingTop:30}}>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        onClick={event => removeFields(index, event)}
                                    >
                                        Remove Student
                                    </Button>
                                </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </div>
                )
                })}
            </form>
            <Box sx={{display:"flex", justifyContent: "center", paddingTop:30}}>
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={addFields}
                >
                    Add Student
                </Button>
            </Box>
            <Box sx={{display:"flex", justifyContent: "flex-end", paddingTop:30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                    setPage((currPage) => currPage - 1);
                    }}
                >
                    Prev
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={formData.students.length > 0 && formData.intakeId ? disableButton : true}
                    onClick={() => {setOpen(true)}}
                >
                   Submit
                </Button>
                <Dialog open={open} >
                    <DialogContent>
                        <DialogContentText>
                            <span style={{color: 'black', fontWeight: 400}}>Would you like to enrol the following {formData.students.length} student(s) into {formData.intakeName}?</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={() => {setOpen(false)}}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={sendPayload}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {apiResError && (<Box sx={{display:"flex", justifyContent: "center", paddingTop:30}}>
                                <Alert severity="error">
                                    <AlertTitle>Oops!</AlertTitle>
                                        There was an error submitting your request please try again.<br/>
                                        If the problem persists please call <strong>0800 66 55 44.</strong>
                                </Alert>
                            </Box>
            )}
        </>
    )
}

export default StudentDetails;