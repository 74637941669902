import {green, orange, lightBlue, red} from "@material-ui/core/colors";
import {createTheme} from "@material-ui/core";

const defaultPalette = {
  success: green,
  warning: orange,
  error: red,
  info: lightBlue
};

const allHeaders = {
  fontFamily: "Sans-serif",
  fontWeight: 600,
  lineHeight: 2.0
};

// A root theme shared by all child pages

const rootDefaults = {
  //spacing: 16,
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    useNextVariants: true,
    h1: {...allHeaders},
    h2: {...allHeaders},
    h3: {fontSize: "20px", fontWeight: 600},
    h4: {...allHeaders},
    h5: {...allHeaders},
    h6: {...allHeaders}
  },
  palette: {
    primary: {
      main: "#006A90"
    },
    secondary: {
      main: "#555"
    },
    ...defaultPalette
  },
  status: {
    danger: "red"
  },

  overrides: {
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em",
        whiteSpace: "nowrap",
        padding: "5px 50px"
      }
    },
    MuiToggleButton: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%"
        }
      }
    },
    MuiTypography: {
      root: {
        "& dt": {
          flexBasis: "20%",
          textAlign: "left",
          padding: "2px 4px",
          fontWeight: "bold"
        },
        "& dd": {
          flexBasis: "70%",
          flexGrow: 1,
          margin: 0,
          padding: "2px 4px"
        },
        "& dl": {
          display: "flex",
          flexFlow: "row wrap"
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    }
  }
};
const rootTheme = createTheme(rootDefaults);

export {rootTheme};