import React from "react";
import { TextField, Grid, Typography, Box, Button } from "@material-ui/core";

function UserDetails({ formData, setFormData, setPage, errorState, setErrorState }) {

    const doValidation = (e) => {
        let data = {...errorState}
        if (e.target.name === "emailAddress" && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value))) {
            data.userDetails[e.target.name] = true
            setErrorState(data);
        }
        else if (e.target.value === "") {
            data.userDetails[e.target.name] = true;
            setErrorState(data)
        } else {
            data.userDetails[e.target.name] = false;
            setErrorState(data)
        }
    }

    const disableButton = !(formData.school.firstName && formData.school.lastName && (formData.school.emailAddress && !errorState.userDetails.emailAddress));
    
    return (
        <>
        <form>
            <Typography style={{marginBottom: "1em"}} variant="h5">
                Your Details
            </Typography>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First Name" 
                        name="firstName"
                        type="text"
                        variant="outlined"
                        required
                        fullWidth
                        value={formData.school.firstName}
                        error={errorState.userDetails.firstName}
                        helperText={errorState.userDetails.firstName && "First Name is required"}
                        InputProps={{onBlur: (e) => doValidation(e)}} //runs validation when clicking away from textfield 
                        onChange={(event) =>
                            {
                                setFormData({ ...formData, school: {...formData.school, firstName: event.target.value} })
                                doValidation(event);
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Middle Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={formData.school.middleName}
                        onChange={(event) => {
                            setFormData({ ...formData, school: {...formData.school, middleName: event.target.value} })
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Last Name"
                        name="lastName"
                        type="text"
                        variant="outlined"
                        required
                        fullWidth
                        value={formData.school.lastName}
                        error={errorState.userDetails.lastName}
                        helperText={errorState.userDetails.lastName && "Last Name is required"}
                        InputProps={{onBlur: (e) => doValidation(e)}} //runs validation when clicking away from textfield 
                        onChange={(event) => {
                            setFormData({ ...formData, school: {...formData.school, lastName: event.target.value} })
                            doValidation(event);

                            }
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="emailAddress"
                        type="email"
                        variant="outlined"
                        required
                        fullWidth
                        value={formData.school.emailAddress}
                        error={errorState.userDetails.emailAddress}
                        helperText={formData.school.emailAddress && errorState.userDetails.emailAddress ? "Email must be a valid format" 
                                                                                            : !formData.school.emailAddress && errorState.userDetails.emailAddress ? "Email is required"
                                                                                            : null} //gives error message based on if the email field is empty or invalid
                        InputProps={{onBlur: (e) => doValidation(e)}}
                        onChange={(event) => {
                            setFormData({ ...formData, school: {...formData.school, emailAddress: event.target.value} })
                            doValidation(event);

                            }
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Mobile Number"
                        type="tel"
                        variant="outlined"
                        fullWidth
                        value={formData.school.mobilePhone}
                        onChange={(event) =>
                        setFormData({ ...formData, school: {...formData.school, mobilePhone: event.target.value} })
                        }
                    />
                </Grid>
            </Grid>
        <Box sx={{display:"flex", justifyContent: "flex-end", paddingTop:30}}>
        <Button
            variant="contained"
            color="primary"
            disabled={disableButton}
            onClick={() => {
                setPage((currPage) => currPage + 1);
                }}
        >
            Next
        </Button>
        </Box>
        </form>
    </>
    );
}

export default UserDetails;