import React, { useState, useEffect } from "react";
import UserDetails from "./UserDetails";
import StudentDetails from "./StudentDetails";
import Completed from "./Completed";
import ChooseCourse from './ChooseCourse';
import UpApi from 'up-api';
import { Container, Box, Stepper, Step, StepLabel, makeStyles } from "@material-ui/core"
import Footer from '../Footer';

const useStyles = makeStyles(
  (theme) => {
    const isMobile = theme.breakpoints.down("xs");
    return {
      StepLabelCompleted: {
        color: [theme.palette.primary.main, "!important"]
      },
      StepLabelRoot: {
        color: theme.palette.primary.light,
        "&.Mui-disabled": {
          color: [theme.palette.text.disabled, "!important"]
        }
      },
      StepLabelLabel: {
        color: "inherit",
        [isMobile]: {
          display: "none"
        }
      },
      StepLabelActive: {
        color: [theme.palette.primary.main, "!important"],
        fontWeight: ["bold", "!important"],
        [isMobile]: {
          display: "block"
        }
      }
    }
  }
);

function Form() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [fetchCourses, setfetchCourses] = useState([]);
  const [formData, setFormData] = useState({
    accountId: "",
    intakeId: "",
    intakeName: "",
    providerId: "",
    school: {
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        mobilePhone: ""
    },
    students: [
        {
            firstName: "",
            middleName: "",
            lastName: "",
            preferredName: "",
            nsn: "",
            emailAddress: "",
            mobilePhone: "",
            dateOfBirth: "",
            gender: "",
            disability: "",
            disabilityNote: "",
            emergencyContact: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                mobilePhone: ""
            }
        }
    ]
  });
  
  const [errorState, setErrorState ] = useState({
    intakeId: false,
    userDetails: {
      firstName: false,
      lastName: false,
      emailAddress: false
    },
    studentDetails: [{
      firstName: false,
      lastName: false,
      emailAddress: false,
      nsn: false,
      dateOfBirth: false,
      disability: false,
      emergencyContact: {
        firstName: false,
        lastName: false,
        emailAddress: false
      }
    }]
  })

  //Calls getproduct 
  useEffect(() => {
    const getCourses = async () => {
        try {
            const res =  await UpApi.courses('yoobee-short-courses');
            setfetchCourses(res);
        } catch (e) {
            console.log("error", e);
        }
    };
    getCourses();
  }, []);

  //Takes account guid from url and adds it to local state and then removes it from url
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlAccountId = urlParams.get("accountId");
    setFormData(f => ({...f, accountId: urlAccountId}));
    window.history.replaceState && window.history.replaceState(
        null, '', window.location.pathname + window.location.search.replace(/[?&]accountId=[^&]+/, '').replace(/^&/, '?')
    ); 
  }, []);

  const steps = ["User Details", "Course/Student Details", "Completed"];

  const PageDisplay = () => {
    if (page === 0) {
      return <UserDetails formData={formData} setFormData={setFormData} setPage={setPage} errorState={errorState} setErrorState={setErrorState} />;
    } else if (page === 1) {
      return  <>
                <ChooseCourse formData={formData} setFormData={setFormData} courses={fetchCourses} errorState={errorState} setErrorState={setErrorState} />
                <StudentDetails formData={formData} setFormData={setFormData} setPage={setPage} errorState={errorState} setErrorState={setErrorState}/>
              </>;
    } else {
      return <Completed formData={formData} />;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{paddingTop:10}}>
      <Stepper activeStep={page}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel 
                classes={{
                          root: classes.StepLabelRoot,
                          label: classes.StepLabelLabel,
                          completed: classes.StepLabelCompleted,
                          active: classes.StepLabelActive
                }}
                >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      </Box>
        <div className="body">{PageDisplay()}</div>
      <Box sx={{paddingTop:30}}>
        <Footer />
      </Box>
    </Container>
  );
}

export default Form;