/**
 * Footer bar
 */
import React from "react";
import {Grid, Typography, useMediaQuery, Link as ExternalLink} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingTop: "1em"
  },
  copyright: {
    fontSize: "9px"
  },
  logo: {
    fill: theme.palette.text.secondary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    verticalAlign: "middle"
  },
  needHelp: {
    textAlign: "right",
    "& a": {
      textDecoration: "none",
      color: "inherit"
    }
  },
  links: {
    lineHeight: "2em"
  }
}));

export default function Footer(props) {
  const 
    classes = useStyles(),
    theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Typography className={classes.root} variant="body2" component="footer">
      <Grid container justifyContent="space-between" alignContent="center" alignItems="flex-start" spacing={2}>
        <Grid
          item
          container
          className={classes.links}
          xs={6}
          justifyContent="space-between"
          direction={isMobile ? "column" : "row"}
        >
          <ExternalLink target="_blank" rel="noopener" href={"https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"} color="primary">
            Privacy Policy
          </ExternalLink>
          <ExternalLink target="_blank" rel="noopener" href={"https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"} color="primary">
            Cookie Policy
          </ExternalLink>
          <ExternalLink target="_blank" rel="noopener" href={"https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"} color="primary">
            Terms
          </ExternalLink>
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={classes.needHelp}
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "flex-end"}
          justifyContent="flex-end"
          spacing={isMobile ? 0 : 1}
        >
          <Grid item>
            <Typography style={{fontWeight: "bold"}} variant="body1">
                Need help?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
                Call 0800 66 55 44
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.copyright} variant="body1">
            Yoobee Colleges has been awarded with a Category One rating by the New Zealand Qualifications Authority (NZQA), the highest possible rating showing that the government has confidence in our ability to deliver high quality education.
          </Typography>
        </Grid>
        <Grid item xs={2} style={{textAlign: "right"}}>
        </Grid>
      </Grid>
    </Typography>
  );
}
