import {AppBar, Toolbar, Typography, makeStyles} from "@material-ui/core";
import React from "react";
import logo from "./images/yoobee-logo.png";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default
	  },
	colorPrimary: {
	color: theme.palette.getContrastText(theme.palette.background.default)
	},
	title: {
		flexGrow: 1,
		textTransform: "none",
		paddingTop: 50,
		fontSize: "medium"
	  },
	logo: {
		maxHeight: "4em",
		objectFit: "contain"
	  }
}))

const BulkAppBar = () => {
	const classes = useStyles()
	return (
		<AppBar
			color="primary"
			classes={{root: classes.root, colorPrimary: classes.colorPrimary}}
			elevation={1}
			position="static"
			
		>
			<Toolbar>
				<img src={logo} alt={logo} className={classes.logo}/>
				<Typography variant="h6" align="right" className={classes.title}>
          			Short Courses Bulk Application
        		</Typography>
			</Toolbar>
		</AppBar>
	);
};

export default BulkAppBar;