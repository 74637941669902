import React from 'react';
import UpApi from "up-api";
import Form from './components/Form';
import './App.css';
import BulkAppBar from './BulkAppBar';
import { ThemeProvider } from '@material-ui/core';
import { rootTheme } from './theme';
import config from './config';

const { upApi: {baseUri} } = config

UpApi.configure({
  baseUri,
  headers: {source: "bulk-enrol"}
});

const App = () => {

  return (
    <ThemeProvider theme={rootTheme}>
        <BulkAppBar />
        <Form />
    </ThemeProvider>
  )
}

export default App;
